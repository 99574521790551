<template>
  <b-card header="Enabled Services" class="mb-4 shadow-sm" no-body>
    <b-list-group flush class="mb-2">
      <b-list-group-item v-for="(svc, idx) in account.services" :key="idx" class="d-flex justify-content-between align-items-center">
        <b-checkbox v-model="svc.enabled">{{svc.name}}</b-checkbox>
        <div>
          <b-select v-model="svc.profile" :disabled="!svc.enabled" size="sm">
            <option :value="null" disabled>(no profile)</option>
            <option v-for="profile in svc.profiles" :key="profile.id" :value="profile">{{profile.name}}</option>
          </b-select>
        </div>
      </b-list-group-item>
    </b-list-group>

    <div class="p-2">
      <b-btn variant="primary" @click="saveServices()" :disabled="busy">
        <template v-if="busy"><icon-text icon="spinner" spin>Saving...</icon-text></template>
        <template v-else><icon-text icon="save">Save service selection</icon-text></template>
      </b-btn>
    </div>
  </b-card>
</template>

<script>
import IconText from '../../../../components/IconText'

export default {
  props: ['account'],
  components: { IconText },
  data: () => ({
    busy: false
  }),
  methods: {
    async saveServices () {
      this.busy = true
      try {
        await API.accounts.services.update(this.account, this.account.services)
        this.$emit('reload')
      } catch (error) {
        alert(error)
      } finally {
        this.busy = false
      }
    }
  }
}
</script>
