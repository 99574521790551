<template>
  <b-card header="Validity" class="mb-4 shadow-sm">
    <b-form-group label-cols="2" label="Valid From" label-for="valid_from" label-class="font-weight-bold">
      <b-datepicker id="valid_from" v-model="account.valid_from" />
    </b-form-group>
    <b-form-group label-cols="2" label="Valid To" label-for="valid_to" label-class="font-weight-bold">
      <b-input-group>
        <b-input-group-prepend is-text>
          <b-checkbox v-model="hasExpiration">Expires</b-checkbox>
        </b-input-group-prepend>
        <b-datepicker id="valid_to" v-model="account.valid_to" :disabled="!hasExpiration" />
      </b-input-group>
    </b-form-group>
    <b-row class="mb-3">
      <b-col offset="2">
        <b-btn variant="primary" @click="saveValidity()" :disabled="busy">
          <icon-text v-if="busy" icon="spinner" spin>Saving...</icon-text>
          <icon-text v-else icon="save">Save changes</icon-text>
        </b-btn>
      </b-col>
    </b-row>

    <b-alert show variant="warning" v-if="account.status === 'not-started'">This account is <b>not yet active</b>.</b-alert>
    <b-alert show variant="warning" v-if="account.status === 'expired'">This account has <b>expired</b>.</b-alert>
    <b-alert show variant="success" v-if="account.status === 'active'">This account is within its valid period.</b-alert>
  </b-card>
</template>

<script>
import IconText from '@/components/IconText'

export default {
  props: ['account'],
  components: { IconText },
  data: () => ({
    busy: false
  }),
  computed: {
    hasExpiration: {
      get () {
        return this.account.valid_to !== null
      },
      set (v) {
        if (v) {
          this.account.valid_to = new Date()
        } else {
          this.account.valid_to = null
        }
      }
    }
  },
  methods: {
    reload () {
      this.$emit('reload')
    },
    async saveValidity () {
      this.$emit('update-start')
      this.busy = true
      try {
        await API.accounts.update({ id: this.account.id, valid_from: this.account.valid_from, valid_to: this.account.valid_to })
        this.reload()
      } catch (error) {
        alert(error)
      } finally {
        this.busy = false
        this.$emit('update-finish')
      }
    }
  }
}
</script>
