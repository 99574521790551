<template>
  <b-card class="shadow-sm mb-4" header="Account Details">
    <b-form-group label-cols="2" label="Name" label-for="name" label-class="font-weight-bold">
      <b-input id="name" v-model="account.name" />
    </b-form-group>
    <b-form-group label-cols="2" label="Email Address" label-for="email" label-class="font-weight-bold">
      <b-input id="email" type="email" v-model="account.email_address" />
    </b-form-group>
    <b-form-group label-cols="2" label="Username" label-for="username" label-class="font-weight-bold">
      <b-input id="username" :value="account.username" readonly />
    </b-form-group>
    <b-form-group label-cols="2" label="Role" label-for="role" label-class="font-weight-bold">
      <b-select id="role" v-model="account.role">
        <option value="user">User</option>
        <option value="supervisor">Supervisor</option>
        <option value="admin">Admin</option>
      </b-select>
    </b-form-group>
    <b-row>
      <b-col offset="2">
        <b-btn variant="primary" @click="saveDetails()" :disabled="busy">
          <icon-text v-if="busy" icon="spinner" spin>Saving...</icon-text>
          <icon-text v-else icon="save">Save changes</icon-text>
        </b-btn>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import IconText from '@/components/IconText'

export default {
  props: ['account'],
  components: { IconText },
  data: () => ({
    busy: false
  }),
  methods: {
    async saveDetails () {
      this.$emit('update-start')
      this.busy = true
      try {
        await API.accounts.update({ id: this.account.id, name: this.account.name, email_address: this.account.email_address, username: this.account.username, role: this.account.role })
        this.$emit('reload')
      } catch (error) {
        alert(error)
      } finally {
        this.busy = false
        this.$emit('update-finish')
      }
    }
  }
}
</script>
