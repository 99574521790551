<template>
  <b-card header="Authentication" class="mb-4 shadow-sm">
    <b-row class="mb-3">
      <b-col cols="2" class="font-weight-bold">Last Authenticated:</b-col>
      <b-col>
        <span v-if="account.last_authenticated === null" class="text-muted">(never)</span>
        <div class="d-flex justify-content-between" v-else>
          <icon-text :icon-variant="account.last_authenticated.success ? 'success' : 'danger'" :icon="account.last_authenticated.success ? 'check' : 'times'">{{formatAuthDate(account.last_authenticated.timestamp)}}</icon-text>
          <a href="#" @click.prevent="showLog()"><icon-text icon="search">Show log</icon-text></a>
        </div>
      </b-col>
    </b-row>

    <b-btn @click="resetPassword()" variant="primary">Reset Password</b-btn>

    <authentication-log-dialog ref="authenticationLogDialog" />
    <reset-password-dialog ref="resetPasswordDialog" />
  </b-card>
</template>

<script>
import AuthenticationLogDialog from './AuthenticationLogDialog'
import ResetPasswordDialog from '@/components/ResetPasswordDialog'
import IconText from '@/components/IconText'

export default {
  props: ['account'],
  components: { AuthenticationLogDialog, ResetPasswordDialog, IconText },
  methods: {
    formatAuthDate (d) {
      const dateObject = new Date(d)
      return `${dateObject.toLocaleDateString('en-GB')} @ ${dateObject.toLocaleTimeString('en-GB')}`
    },
    resetPassword () {
      this.$refs.resetPasswordDialog.show(this.account)
    },
    showLog () {
      this.$refs.authenticationLogDialog.show(this.account)
    }
  }
}
</script>
