<template>
  <b-modal ref="dialog" title="Reset Password" size="lg">
    <b-overlay :show="busy">
      <template v-if="acct">
        <p>You are resetting the password for <b>{{acct.name}}</b>:</p>
        <b-form-group label="Reset Mode" label-class="font-weight-bold">
          <b-radio v-model="operation.mode" value="email">Send a self-service reset email.</b-radio>
          <b-radio v-model="operation.mode" value="manual">Manually specify password.</b-radio>
        </b-form-group>

        <template v-if="operation.mode === 'manual'">
          <b-form-group
            label-cols="2"
            label="New Password"
            label-for="new-pwd"
            label-class="font-weight-bold"
            :state="newPasswordState"
            :invalid-feedback="newPasswordFeedback"
          >
            <b-input
              id="new-pwd"
              type="password"
              :state="newPasswordState"
              v-model="operation.new_password"
            />
          </b-form-group>

          <b-form-group
            label-cols="2"
            label="Confirm Password"
            label-for="cnf-pwd"
            label-class="font-weight-bold"
            :state="confirmPasswordState"
            :invalid-feedback="confirmPasswordFeedback"
          >
            <b-input
              id="cnf-pwd"
              type="password"
              :state="confirmPasswordState"
              v-model="operation.confirm_password"
            />
          </b-form-group>

          <b-checkbox v-model="operation.notify">Send a notification email containing the new password</b-checkbox>
        </template>
      </template>
    </b-overlay>
    <template slot="modal-footer">
      <b-btn variant="primary" @click="doReset" :disabled="busy || !canResetPassword">Reset Password</b-btn>
      <b-btn variant="secondary" @click="$refs.dialog.hide()" :disabled="busy">Cancel</b-btn>
    </template>
  </b-modal>
</template>

<script>
export default {
  data: () => ({
    busy: false,
    acct: null,
    operation: {
      mode: 'email',
      notify: true,
      new_password: '',
      confirm_password: ''
    }
  }),
  computed: {
    newPasswordState () {
      return this.operation.new_password.length === 0 ? null : this.operation.new_password.length > 7
    },
    newPasswordFeedback () {
      if (this.operation.new_password.length < 8) {
        return 'Password too short'
      }

      return 'Error'
    },
    confirmPasswordState () {
      return this.operation.confirm_password.length === 0 ? null : this.operation.confirm_password === this.operation.new_password
    },
    confirmPasswordFeedback () {
      if (this.operation.confirm_password !== this.operation.new_password) {
        return 'New and confirm passwords do not match'
      }

      return 'Error'
    },
    canResetPassword () {
      return this.operation.mode === 'email' || (this.operation.mode === 'manual' && (this.newPasswordState === true && this.confirmPasswordState === true))
    }
  },
  methods: {
    show (acct) {
      this.acct = Object.assign({}, acct)
      this.operation = Object.assign({}, this.operation, {
        mode: 'email',
        notify: true,
        new_password: '',
        confirm_password: ''
      })

      this.$refs.dialog.show()
    },
    async doReset () {
      this.busy = true
      try {
        await API.accounts.reset_password(this.acct, this.operation)
        this.$store.dispatch('dialog/showInfo', { title: 'Reset Password', message: 'The user\'s password has been reset.' })
      } catch (error) {
        this.$store.dispatch('dialog/showError', { title: 'Unable to reset password', message: 'An error occurred' })
      } finally {
        this.busy = false
        this.$refs.dialog.hide()
      }
    }
  }
}
</script>
