<template>
  <b-modal ref="dialog" title="Authentication Log" size="lg">
    <b-container v-if="log" fluid>
      <b-overlay :show="busy">
        <table class="table table-sm">
          <thead>
            <tr>
              <th width="180px">Timestamp</th>
              <th width="200px">User</th>
              <th width="180px">Result</th>
              <th>Device</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="l in pagedLog" :key="l.id">
              <td>{{formatAuthDate(l.timestamp)}}</td>
              <td>{{l.authuser}}</td>
              <td><icon-text :icon="l.success ? 'check' : 'times'">{{l.reply}}</icon-text></td>
              <td>{{l.calling}}</td>
            </tr>
          </tbody>
        </table>
      </b-overlay>
    </b-container>
    <template slot="modal-footer">
      <b-btn variant="secondary" @click="$refs.dialog.hide()">Close</b-btn>
    </template>
  </b-modal>
</template>

<script>
import IconText from '@/components/IconText'

export default {
  components: { IconText },
  data: () => ({
    busy: false,
    log: null
  }),
  computed: {
    pagedLog () {
      return this.log.slice(0, 10)
    }
  },
  methods: {
    formatAuthDate (d) {
      const dateObject = new Date(d)
      return `${dateObject.toLocaleDateString('en-GB')} ${dateObject.toLocaleTimeString('en-GB')}`
    },
    async show (account) {
      this.log = null
      this.$refs.dialog.show()

      this.busy = true
      try {
        this.log = await API.accounts.get_auth_log(account)
      } catch (error) {
        alert(error)
      } finally {
        this.busy = false
      }
    }
  }
}
</script>
