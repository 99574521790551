<template>
  <b-overlay :show="loading">
    <b-alert v-if="error" show variant="danger"><icon-text icon="exclamation-circle">An error occurred whilst loading the account data: {{error}}</icon-text></b-alert>

    <template v-if="account">
      <b-alert show :variant="statusVariant" class="d-flex justify-content-between">
        <icon-text :icon="statusIcon">This account is <b>{{ account.enabled ? 'enabled' : 'disabled' }}</b><template v-if="account.enabled && account.status !== 'active'">, but it is <b>not active</b></template>.</icon-text>
        <b-btn size="sm" variant="primary" @click="setAccountEnablement(!account.enabled)">{{ account.enabled ? 'Disable' : 'Enable' }} Account</b-btn>
      </b-alert>

      <account-details :account="account" @reload="reload" />
      <validity-details :account="account" @reload="reload" />
      <authentication-details :account="account" @reload="reload" />
      <services :account="account" @reload="reload" />
    </template>
  </b-overlay>
</template>

<script>
import AccountDetails from './AccountDetails'
import AuthenticationDetails from './AuthenticationDetails'
import ValidityDetails from './ValidityDetails'
import Services from './Services'
import IconText from '@/components/IconText'

export default {
  components: { AccountDetails, AuthenticationDetails, IconText, ValidityDetails, Services },
  data: () => ({
    loading: true,
    error: null,
    account: null
  }),
  computed: {
    statusVariant () {
      return this.account.enabled && this.account.status === 'active' ? 'success' : (this.account.enabled ? 'warning' : 'danger')
    },
    statusIcon () {
      return this.account.enabled ? 'check' : 'times'
    }
  },
  methods: {
    async reload () {
      this.loading = true
      this.error = null
      try {
        this.account = await API.accounts.get(this.$route.params.accountId)
      } catch (error) {
        this.error = error
      } finally {
        this.loading = false
      }
    },
    async setAccountEnablement (enabled) {
      if (!await this.$store.dispatch('dialog/showConfirm', {
        title: `${enabled ? 'Enable' : 'Disable'} Account`,
        message: `Are you sure you want to ${enabled ? 'enable' : 'disable'} this account?`
      })) {
        return
      }

      this.loading = true
      try {
        await API.accounts.update({ id: this.account.id, enabled })
        this.reload()
      } catch (error) {
        alert(error)
      } finally {
        this.loading = false
      }
    }
  },
  mounted () {
    this.reload()
  }
}
</script>
